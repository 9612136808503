import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer4 from './Footer4';

const StudentCircular = () => {
  const [circulars, setCirculars] = useState([]); // Array to hold circulars
  const [loading, setLoading] = useState(false); // Loading state

  // Fetch all circulars when the component mounts
  useEffect(() => {
    fetchCirculars();
  }, []);

  const fetchCirculars = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://demohsbackend-production.up.railway.app/circular');
      console.log("Fetched circulars:", response.data); // Debugging
      setCirculars(response.data);
    } catch (error) {
      console.error('Error fetching circulars:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>

 <Navbar/>    
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-xl shadow-lg">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Circulars</h1>

      {loading ? (
        <p className="text-center text-gray-600">Loading circulars...</p>
      ) : (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 text-left font-bold text-gray-800">Title</th>
              <th className="py-2 px-4 text-left font-bold text-gray-800">Drive Link</th>
            </tr>
          </thead>
          <tbody>
            {circulars.length > 0 ? (
              circulars.map((circular) => (
                <tr key={circular.id} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4 text-gray-800">{circular.title}</td>
                  <td className="py-2 px-4 text-blue-500 underline">
                    <a href={circular.driveLink} target="_blank" rel="noopener noreferrer">
                      View Circular
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="py-4 text-center text-gray-600">
                  No circulars available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
    <Footer4/>
    </div>
  );
};

export default StudentCircular;
